import { UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Form, notification, Space } from 'antd';
import { Formik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as nprogress from '../../../utils/nprogress';
import { useHttp } from '../../contexts/HttpContext';
import { useSession } from '../../contexts/SessionContext';
import MsalButton from '../../ui/MsalButton';
import { SubmitButton } from '../lib-formik';
import { loginMutation } from './api.graphql';
import * as fields from './fields';
import style from './style.module.css';

const useSubmit = t => {
    const { update } = useSession();
    const client = useApolloClient();

    return useCallback(
        async values => {
            try {
                // try to log in
                const promise = client.mutate({ mutation: loginMutation, variables: values });

                // listen to the promise with nprogress
                // and wait for the data
                const { data } = await nprogress.wrapPromise(promise);
                const { response } = data;

                // extract the key information
                const { ok, session } = response;

                if (!ok) {
                    if (response.error) {
                        notification.error({
                            message: t('public:messages.attemptFailure.title'),
                            description: response.error,
                        });
                    } else {
                        // display the error notification
                        notification.error({
                            message: t('public:messages.attemptFailure.title'),
                            description: t('public:messages.attemptFailure.description'),
                        });
                    }
                    return null;
                }
                if (ok & !session) {
                    return null;
                }

                // get the user's first name
                const { firstName } = session.user;

                // display success notification
                notification.success({
                    message: t('public:messages.attemptSuccess.title'),
                    description: t('public:messages.attemptSuccess.description', { name: firstName }),
                });

                // update session
                update(session);

                return Promise.resolve();
            } catch (error) {
                // print the error on console
                console.error(error);
            }

            // wait for at least 500ms (no spam)
            return new Promise((resolve, reject) => {
                setTimeout(reject, 500);
            });
        },
        [client, update, t]
    );
};

const initialValues = {};

const LoginForm = () => {
    const { t } = useTranslation(['public', 'forms']);
    const onSubmit = useSubmit(t);
    const { GET } = useHttp();
    let [searchParams, setSearchParams] = useSearchParams();
    const [formSubmitted, setFormSubmited] = React.useState(false);

    useEffect(() => {
        const code = searchParams.get('code');
        const magicLink = searchParams.get('magic_link');
        if (code) {
            setSearchParams({});
            onSubmit({ code });
        }
        if (magicLink) {
            setSearchParams({});
            onSubmit({ magicLink });
        }
    }, [GET, searchParams]);

    return (
        <Space direction="vertical">
            <MsalButton />
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleSubmit, values, submitCount }) => {
                    if (submitCount != 0)
                        return <div style={{ width: 240 }}>{t('forms:login.emailSent', { email: values?.email })}</div>;

                    return (
                        <Form onFinish={handleSubmit}>
                            <fields.EmailField placeholder={t('public:fields.email')} prefix={<UserOutlined />} />
                            <Form.Item>
                                <SubmitButton className={style.loginBtn} type="primary">
                                    {t('public:ui.loginSubmitButton')}
                                </SubmitButton>
                            </Form.Item>
                        </Form>
                    );
                }}
            </Formik>
        </Space>
    );
};

export default LoginForm;
