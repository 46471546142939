import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import frFR from 'antd/locale/fr_FR';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import ApolloClientProvider from './ApolloClientProvider';
import Routes from './Routes';
import { SessionProvider } from './contexts/SessionContext';
import CenteredLayout from './layouts/CenteredLayout';
import { FliptProvider } from '@flipt-io/flipt-client-react';

const lang = {
    fr: frFR,
    en: enUS,
};

const App = () => {
    const { i18n } = useTranslation();

    return (
        <ApolloClientProvider>
            <FliptProvider
                namespace={import.meta.env.MODE}
                options={{
                    url: 'https://flipt.orion.iaudit.fr',
                }}
            >
                <ConfigProvider
                    locale={lang[i18n.language]}
                    // componentSize="medium"
                    theme={{
                        cssVar: true,
                        token: {
                            // here are the global tokens
                            colorTextPlaceholder: '#000000e0',
                            fontSize: 14,
                            fontFamily: '"Barlow", sans-serif',
                            paddingXXL: 48,
                            borderRadius32: 32,
                            borderRadius16: 16,
                            borderRadius24: 24,
                            borderRadius0: 0,
                            colorSplit: '#f0f0f0',
                            colorPrimary: '#5b3073',
                            colorSuccess: '#00cc55',
                            colorWarning: '#ff8b00',
                            colorError: '#e12120',
                            colorTextLight: '#ffffff',
                            colorLink: '#ffbc28',
                            colorLinkHover: '#ffc951',
                            colorLinkActive: '#ffb105',
                            number: 0,
                            'midnight purple.1': '#f4f2f5',
                            'midnight purple.2': '#e8e5ea',
                            'midnight purple.3': '#cec8d2',
                            'midnight purple.4': '#b0a6b8',
                            'midnight purple.5': '#8c7a9a',
                            'midnight purple.6': '#5b3073',
                            'midnight purple.7': '#512b67',
                            'midnight purple.8': '#462559',
                            'midnight purple.9': '#3a1e49',
                            'midnight purple.10': '#291533',
                            'canarie Yellow.1': '#fff9f2',
                            'canarie Yellow.2': '#fff3e5',
                            'canarie Yellow.3': '#ffe7c7',
                            'canarie Yellow.4': '#ffd9a4',
                            'canarie Yellow.5': '#ffcb78',
                            'canarie Yellow.6': '#ffbc28',
                            'canarie Yellow.7': '#e4a824',
                            'canarie Yellow.8': '#c6921f',
                            'canarie Yellow.9': '#a17719',
                            'canarie Yellow.10': '#725412',
                            'orange Pi ata.1': '#fff6f2',
                            'orange Pi ata.2': '#ffece4',
                            'orange Pi ata.3': '#ffd8c6',
                            'orange Pi ata.4': '#ffc2a1',
                            'orange Pi ata.5': '#ffa972',
                            'orange Pi ata.6': '#ff8b00',
                            'orange Pi ata.7': '#e4570c',
                            'orange Pi ata.8': '#c66c00',
                            'orange Pi ata.9': '#a15800',
                            'orange Pi ata.10': '#723e00',
                            'island Green.1': '#f2faf3',
                            'island Green.2': '#e4f6e7',
                            'island Green.3': '#c6eccd',
                            'island Green.4': '#a1e2ae',
                            'island Green.5': '#72d789',
                            'island Green.6': '#00cc55',
                            'island Green.7': '#00b64c',
                            'island Green.8': '#009e42',
                            'island Green.9': '#008136',
                            'island Green.10': '#005b26',
                            'cross My Heart.1': '#fcf2f2',
                            'cross My Heart.2': '#f9e5e5',
                            'cross My Heart.3': '#f7bcbc',
                            'cross My Heart.4': '#eda3a3',
                            'cross My Heart.5': '#c07979',
                            'cross My Heart.6': '#e12120',
                            'cross My Heart.7': '#c91e1d',
                            'cross My Heart.8': '#ae1a19',
                            'cross My Heart.9': '#8e1514',
                            'cross My Heart.10': '#650f0e',
                            'blustering Blue.1': '#f2f2ff',
                            'blustering Blue.2': '#e5e4ff',
                            'blustering Blue.3': '#c8c6ff',
                            'blustering Blue.4': '#a6a2fe',
                            'blustering Blue.5': '#7b74fe',
                            'blustering Blue.6': '#3518fe',
                            'blustering Blue.7': '#2f15e3',
                            'blustering Blue.8': '#2913c5',
                            'blustering Blue.9': '#220fa1',
                            'blustering Blue.10': '#180b72',
                        },
                        components: {
                            Menu: {
                                activeBarHeight: 0,
                                horizontalItemHoverColor: '#ffbb27',
                                horizontalItemSelectedColor: 'black',
                                horizontalItemHoverBg: '#F4F2F5',
                                horizontalItemSelectedBg: '#F4F2F5',
                                horizontalItemBorderRadius: 10,
                            },
                            Button: {
                                colorPrimaryHover: '#FFD373',
                                colorPrimary: 'rgb(255, 187, 39)',
                                primaryShadow: null,
                            },
                            Switch: {
                                colorPrimary: '#ffbb27',
                            },
                            Radio: {
                                buttonSolidCheckedBg: '#FFBC28',
                            },

                            Layout: {
                                headerBg: '#5B3073',
                                // headerHeight: 230,
                            },
                            Avatar: {
                                textFontSizeLG: 22,
                            },
                            Breadcrumb: {
                                lastItemColor: '#FFB105',
                                linkColor: '#FFF',
                            },
                            Segmented: {
                                itemSelectedBg: '#FFBC28',
                                trackBg: '#fff',
                            },
                            Table: {
                                stickyScrollBarBg: '#dfdcdcf5',
                            },
                            Tabs: {
                                cardBg: '#fafafa',
                                itemSelectedColor: '#1677ff',
                            },
                        },
                    }}
                >
                    <Suspense fallback={<CenteredLayout />}>
                        <BrowserRouter>
                            <SessionProvider>
                                <Routes />
                            </SessionProvider>
                        </BrowserRouter>
                    </Suspense>
                </ConfigProvider>
            </FliptProvider>
        </ApolloClientProvider>
    );
};

export default App;
